<template>
  <div class="ps-section__right">
    <div class="ps-section--account-setting">
      <div class="ps-section__content">
        <div
            class="d-sm-flex align-items-center justify-content-between mb-4 mt-4"
        >
          <h3>Currencies</h3>
          <div class="justify-content-between">
              &nbsp;&nbsp;
              <router-link
                  :to="{ name: 'admin_create_currency' }"
                  v-if="auth.isAuthenticated"
                  class="ps-btn btn-sm"
              ><i class="icon-plus"></i> Create</router-link
              >
          </div>
        </div>

        <div class="table-responsive">
          <table class="table ps-table ps-table--vendor table-bordered">
            <thead>
                <tr>
                  <th>Name</th>
                  <th>Code</th>
                  <th>Value</th>
                  <th>Status</th>
                  <th>Last Updated</th>
                  <th>Actions</th>
                </tr>
            </thead>
            <tbody>
              <tr v-for="currency in currencies" :key="currency.id">
                <td>{{ currency.name }}</td>
                <td>{{ currency.code }}</td>
                <td>{{ currency.rate }}</td>
                <td>
                  <i class="icon-check text-success" v-if="currency.is_active"></i>
                  <i class="icon-cross text-danger" v-if="!currency.is_active"></i>
                </td>
                <td>{{ currency.updated_at | formatDate }}</td>
                <td>
                  <router-link
                      :to="{ name: 'admin_edit_currency', params: {id: currency.id}  }"
                      v-if="auth.isAuthenticated"
                  ><i class="icon-pencil"></i> </router-link>
                  &nbsp;&nbsp;
                </td>
              </tr>
            </tbody>
            <tfoot>
            <tr v-if="(total === 0) && !isLoading">
              <td colspan="7" class="text-center m-4 text-danger p-4">
                No currencies available
              </td>
            </tr>
            <tr v-else-if="isLoading">
              <td colspan="7" class="text-center m-4 text-danger">
                Loading currencies...
              </td>
            </tr>
            <tr v-else>
              <td class="text-right" colspan="7">
                <ul class="pagination text-right">
                  <li v-on:click="goToPrevPage()" v-if="hasPrevPage">
                    <a href="#">Prev Page<i class="icon-chevron-left"></i></a>
                  </li>
                  <li class="active">
                    <a href="#">Page {{ pageNumber }}</a>
                  </li>
                  <!--                      <li><a href="#">3</a></li>-->
                  <li v-on:click="goToNextPage()" v-if="hasNextPage">
                    <a href="#"
                    >Next Page<i class="icon-chevron-right"></i
                    ></a>
                  </li>
                </ul>
              </td>
            </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import {mapState} from "vuex";
export default {
  data() {
    return {
      currencies: [],
      meta: null,
      links: {},
      total: 0,
      hasNextPage: false,
      nextPage: null,
      hasPrevPage: false,
      previousPage: null,
      pageNumber: 1,
      isLoading: false,
    };
  },
  computed: {
    ...mapState({
      auth: (state) => state.auth,
    }),
  },
  mounted() {
    this.getSettings();
  },
  methods: {
    getSettings() {
      this.isLoading = true;
      axios.get("currencies").then((response) => {
          this.currencies = response.data.data;
          this.links = response.data.links;
          this.total = response.data.total;
          this.pageNumber = response.data.current_page;
          this.handleNextPage(response.data.links);
          this.handlePrevPage(response.data.links);
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
        });
    },

    scrollTop() {
      let element = document.getElementsByClassName("table-responsive");
      if (element.length > 0) {
        const y = element[0].getBoundingClientRect().top + window.scrollY - 95;
        window.scroll({
          top: y,
          behavior: "smooth",
        });
      }
    },

    goToNextPage: function() {
      this.url = this.nextPage;
      if (this.url) {
        this.getSettings();
      }
      this.scrollTop();
    },

    goToPrevPage: function() {
      this.url = this.prevPage;
      if (this.url) {
        this.getSettings();
      }
      this.scrollTop();
    },

    handleNextPage(links) {
      if (links.next) {
        this.hasNextPage = true;
        this.nextPage = links.next;
      } else {
        this.hasNextPage = false;
      }
    },

    handlePrevPage(links) {
      if (links.prev) {
        this.hasPrevPage = true;
        this.prevPage = links.prev;
      } else {
        this.hasPrevPage = false;
      }
    }
  },
};
</script>
